<template>
  <div>
    <v-row>
      <v-col cols="12">
        <redirect class="mb-5" title="Member" route="website.member" />
        <div class="page-header">
          <Notification v-if="error" :messages="error" type="error" />
          <h2 class="page-title">
            Information member <v-chip>Rank: {{ customer.rank }}</v-chip>
            <v-chip>Point: {{ customer.points || 0 }}</v-chip>
          </h2>
        </div>
      </v-col>
      <v-col v-if="isLoading" cols="12" class="elevation-form">
        <SkeletonLoaders type="card-heading, list-item-three-line" />
      </v-col>
      <v-col v-else cols="12" class="elevation-form">
        <v-row>
          <v-col cols="12" md="3">
            <h3>Information</h3>
          </v-col>
          <v-col cols="12" md="9">
            <v-row>
              <v-col cols="6">
                <InputDefault label="First name" :model="['customer', 'firstName']" />
              </v-col>
              <v-col cols="6">
                <InputDefault label="Last name" :model="['customer', 'lastName']" />
              </v-col>
              <v-col cols="12">
                <InputDefault :isValidate="true" label="Email" :model="['customer', 'email']" />
              </v-col>
              <!-- <v-col cols="5">
                <InputDefault
                  label="Country"
                  :model="['customer', 'country']"
                  typeComponent="select"
                  :itemsSelect="countries"
                  itemText="name"
                  itemValue="name"
                />
              </v-col> -->
              <v-col cols="12">
                <InputDefault label="Phone number" :model="['customer', 'phone']" />
              </v-col>
              <v-col cols="12">
                <InputDefault label="Address" :model="['customer', 'address1']" />
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12">
            <v-divider></v-divider>
          </v-col>
          <!-- <v-col cols="12" md="4">
            <h3>Address</h3>
            <span>The primary address of this customer</span>
          </v-col>
          <v-col cols="12" md="8">
            <v-row>
              <v-col cols="12">
                <InputDefault label="Address" :model="['customer', 'address1']" />
              </v-col>
              <v-col cols="12">
                <InputDefault label="Apartment, suite, etc... (optional)" :model="['customer', 'address2']" />
              </v-col>
              <v-col cols="12">
                <InputDefault label="City" :model="['customer', 'city']" />
              </v-col>
              <v-col cols="6">
                <InputDefault label="Province" :model="['customer', 'province']" />
              </v-col>
              <v-col cols="6">
                <InputDefault label="ZIP/Postal Code" :model="['customer', 'zip']" />
              </v-col>
            </v-row>
          </v-col> -->
        </v-row>
      </v-col>
      <v-col cols="12" class="elevation-2 elevation-form">
        <Resource
          :document="document"
          :filter-fields="null"
          :filter-data="[]"
          :filter-callback="() => {}"
          :searchable="searchableField"
          :columns="columns"
          :update-router-callback="getUpdateRouterData"
          :routeName="updateRouteName"
        />
        <!-- <pagination :page.sync="page"></pagination> -->
        <!-- <v-col cols="12" class="d-flex justify-space-between">
            <limit-page :callback="setLimit" />
            <pagination
              :visible="0"
              :length="1000000"
              v-if="!$parent.isLoading && document && document.length > 0"
              :page.sync="page"
            ></pagination>
          </v-col> -->
      </v-col>
    </v-row>
    <v-dialog persistent v-model="showModalOrder" width="700" style="transform-origin: center top">
      <v-card relative v-if="itemDetail">
        <!-- Title -->
        <v-card-title class="headline"> Detail Order {{ itemDetail.orderName }} </v-card-title>
        <v-row no-gutters d-flex justify="center"> </v-row>

        <!-- Close icon -->
        <div class="close-icon" @click="showModalOrder = false">
          <v-icon>fas fa-times</v-icon>
        </div>
        <v-divider class="mb-3"></v-divider>
        <div class="list-product">
          <div v-for="i in itemDetail.items" :key="i._id" class="px-4">
            <div class="d-flex align-center pa-2">
              <v-avatar tile size="100">
                <img :src="i.image && i.image ? imageHelpers.url(i.image) : '@/assets/images/no-image.png'" alt="" />
              </v-avatar>
              <span class="ml-3 link-add">{{ i.title }}</span>
              <span class="mx-2">x{{ i.qty }}</span>
              <span>Price: {{ i.price }}</span>
            </div>
          </div>
          <div
            style="display: flex;
    justify-content: end;"
            class="px-4 px-12"
          >
            Grand Total: {{ itemDetail.grandTotal }}
          </div>
        </div>

        <v-divider class="mb-3"></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { required, minLength, maxLength, minValue, maxValue, email, helpers, decimal } from 'vuelidate/lib/validators';
import { storeService } from '@/apis/store.s';
import { memberService } from '@/apis/member';
import Resource from '@/components/Resources';
import resourceListMixin from '@/mixins/resource-list';
import { imageFake } from '@/const/image';
import imageHelpers from '@/helpers/image';

export default {
  props: ['id'],
  components: { Resource },

  data() {
    return {
      imageHelpers,
      imageFake,
      itemDetail: null,
      showModalOrder: false,
      document: [],
      customer: {
        email: '',
        firstName: '',
        lastName: '',
        address1: '',
        address2: '',
        city: '',
        province: 'Alabama',
        province_code: 'AL',
        country_code: 'US',
        country: 'United States',
        zip: '',
        phone: '',
      },
      columns: {
        Orders: {
          field: 'orderName',
          kind: 'view',
        },

        paymentStatus: {
          field: 'paymentStatus',
          kind: 'text',
        },
        paymentName: {
          field: 'paymentName',
          kind: 'text',
        },
        GrandTotal: {
          field: 'grandTotal',
          kind: 'text',
        },
      },
      updateRouteName: 'website.member.update',
      searchableField: '',
      sortable: [],
      error: null,
      isLoadingAction: false,
      isLoading: false,
      countries: [],
    };
  },
  validations: {
    customer: {
      email: {
        required,
        email,
      },
    },
  },
  created() {
    this.getCustomer();
  },
  methods: {
    getUpdateRouterData(id) {
      let finItem = this.document.find(item => item.id === id);
      console.log('itemDetail', this.document, id, finItem);
      if (finItem) {
        this.showModalOrder = true;
        this.itemDetail = finItem;
        console.log('itemDetail', this.itemDetail);
      }
    },
    async getCustomer() {
      this.isLoading = true;
      try {
        let customerData = await memberService.getById(this.$route.params.id);
        console.log('memberService', customerData);
        this.customer = customerData.data;
        if (this.customer) {
          let order = await memberService.getOrderById(this.$route.params.id);
          this.document = order.data.orders;
        }
        setTimeout(async () => {}, 1000);
        this.isLoading = false;
        console.log(customerData);
      } catch (error) {
        this.isLoading = false;
        console.log(error);
        // this.error = error;
      }
    },

    async onSubmit() {},
  },
  watch: {},
};
</script>
